.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.snorlax-background {
  background-image: url('images/background.gif');
  background-size: cover;
  background-position: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-area {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 1rem;
  width: 100%;
}

.pixel-text {
  font-family: 'Press Start 2P', cursive;
  color: #104a73;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.project-links {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.pixel-button {
  font-family: 'Press Start 2P', cursive;
  background-color: transparent;
  color: #104a73;
  border: 2px solid #104a73;
  padding: 0.5rem 1rem;
  text-decoration: none;
  display: inline-block;
  font-size: 0.8rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.pixel-button:hover {
  background-color: rgba(16, 74, 115, 0.2);
  transform: scale(1.05);
}